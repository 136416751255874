.option-box {
  max-width: 100%;
  border-radius: 6px 0 0 0;
  background-color: $gray;
  padding: 22px 20px 18px;

  &__title {
    font-size: 21px;
    line-height: 34px;
  }

  hr {
    border-top: 2px solid $yellow;
  }

  &__logo {
    width: 100%;
  }

  &__banner-img {
    min-width: 450px;

    img {
      width: 100%;
    }
    @include media-breakpoint-down(md) {
      min-width: 100%;
    }
  }

  &__banner-text {
    padding: 22px 20px 18px;
  }

  &__price {
    color: $white;
    font-size: 35px;
    line-height: 40px;
  }
}
