.launch-evento {
  position: relative;
  height: 550px;
  background-color: $gray;
  background-image: url('../../images/img-homefds22-online.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom: 1px solid $gray-light;

  @include media-breakpoint-down(sm) {
    height: 500px;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.8;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__text {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__tag {
    font-size: 28px;
    line-height: 30px;
    color: $yellow;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__subtitle {
    font-family: $catamaran;
    font-size: 42px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 28px;
    line-height: 69px;

    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .btn {
    // width: 200px;
    margin-bottom: 30px;

    svg {
      width: 30px;
      margin-left: 16px;
    }
  }
}
