.modal {
  position: fixed;
  width: 570px;
  max-width: 100%;
  z-index: 1040;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid $gray-medium;
  background-color: $gray;
  box-shadow: 0 5px 15px rgba($black, 0.5);
  padding: 20px;

  &__close {
    position: absolute;
    background-color: transparent;
    border: 0;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    z-index: 10;

    &:before,
    &:after {
      position: absolute;
      top: -2px;
      right: 9px;
      width: 2px;
      height: 24px;
      content: '';
      background-color: $white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black;
  opacity: 0.5;
}
