body {
  margin: 0;
  font-family: $catamaran;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 26px;
  font-weight: $regular;
  font-style: normal;
  color: $white;
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
}

.text-small {
  font-size: 16px;
  line-height: 24px;

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

p {
  font-size: 18px;
  line-height: 26px;
}

a,
.link {
  color: $white;
  text-decoration: underline;
  background-color: transparent;
  border: 0;

  &:hover,
  &:focus {
    color: $white;
    outline: none;
  }
}

h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: $regular;
  margin-bottom: 32px;

  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 40px;
  }
}

h2 {
  font-size: 35px;
  line-height: 40px;
  font-weight: $regular;
}

hr {
  height: 2px;
  background-color: $white;
  border-width: 0;
  color: $white;
  margin: 10px 0;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray;
  opacity: 0.9;
  z-index: 101;
}

.icon-close {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 0;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    position: absolute;
    top: -2px;
    right: calc(50% - 1px);
    width: 2px;
    height: calc(100% + 4px);
    content: '';
    background-color: $white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.mask {
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
}
