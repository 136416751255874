.btn {
  display: inline-block;
  color: $white;
  font-size: 16px;
  line-height: 46px;
  font-weight: $bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  min-width: 139px;
  background-color: $red;
  border: 2px solid $red;
  border-radius: 6px;
  padding: 0 16px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &--secondary {
    background-color: transparent;
    border: 2px solid $white;
  }
}
