.card-festival {
  position: relative;
  height: 540px;
  background-size: cover;
  background-position: center top;
  color: $white;
  padding: 30px;

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  &--focus-bottom {
    background-position: center;
  }

  &--focus-left {
    background-position: left top;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.05%, #000000 100%);
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__tag {
    font-size: 28px;
    line-height: 38px;
    color: $yellow;
    text-transform: uppercase;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 23px;
      line-height: 34px;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.58px;
    font-weight: $semibold;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
