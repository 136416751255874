.event-detail {
  &__img {
    width: 56%;
    float: left;
    margin-right: 30px;
    margin-bottom: 16px;
    background-color: $black;

    img {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      float: none;
    }
  }

  &__question {
    display: block;
    padding: 10px;
    background-color: transparent;
    border: 2px solid $white;
    border-radius: 6px;
    color: $white;
  }

  &__description {
    display: block;
  }

  &__streaming {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background-color: $black;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__streaming {
    position: relative;
    width: 100%;
    padding-bottom: 68.85%;
    background-color: $black;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__disciplina {
    display: inline-block;
    font-size: 12px;
    line-height: 19px;
    border: 1.5px solid $white;
    border-radius: 6px;
    padding: 3px 10px;
    text-transform: uppercase;
  }

  &__date {
    svg {
      width: 17px;
    }
  }
}
