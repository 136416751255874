.section {
  padding: 60px 0;

  &--stream-creator {
    padding: 20px 0 160px 0;

    @include media-breakpoint-down(sm) {
      padding: 0px 0 160px 0;
    }

    h1 {
      @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 42px;
      }
    }

    h2 {
      @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    svg {
      max-width: 18px;
    }
  }

  &--stream {
    padding: 20px 0;
    h1 {
      font-size: 72px;
      line-height: 118px;
      font-weight: $bold;
      margin-bottom: 16px;

      @include media-breakpoint-down(sm) {
        font-size: 50px;
        line-height: 56px;
        margin-bottom: 30px;
        padding: 0;
      }
    }

    h2 {
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    svg {
      max-width: 18px;
    }

    .section__date {
      margin-bottom: 70px;
    }
  }

  &--bg-gradient {
    background: linear-gradient(180deg, $gray-dark 0%, $gray-medium 100%);
    padding: 130px 0 65px 0;

    h2 {
      @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }

  &--bg-gray {
    background-color: $gray-dark;
    margin-bottom: 360px;

    .section__content {
      background-color: $gray;
      padding: 30px 30px 1px 30px;
      margin-bottom: -360px;

      @include media-breakpoint-down(sm) {
        padding: 15px 15px 1px 15px;
      }
    }

    h3 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      font-weight: $regular;

      @include media-breakpoint-down(sm) {
        font-size: 22px;
        line-height: 32px;
      }
    }
  }

  &--slider {
    background-color: $gray-dark;
    padding: 50px 0 0 0;
    margin-bottom: 122px;
  }

  &--has-min-height {
    background: linear-gradient(180deg, $gray-dark 0%, $gray-medium 100%);
    min-height: 680px;
    padding: 40px 0;
  }

  &__date {
    margin-bottom: 30px;
    span {
      font-size: 18px;
      line-height: 1;
      margin-left: 12px;
    }
  }

  &__social {
    h3 {
      font-size: 28px;
      line-height: 38px;
      font-weight: $bold;
      text-transform: uppercase;
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    a {
      display: block;
      width: 64px;
      margin-right: 30px;
      @include media-breakpoint-down(sm) {
        width: 40px;
        margin-right: 20px;
      }
    }

    svg {
      width: 100%;
      min-width: 100%;
      @include media-breakpoint-down(sm) {
        min-width: 40px;
      }
    }
  }

  &__text {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0;
    margin-bottom: 12px;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__box-acquista {
    border: 2px solid $white;
    border-radius: 8px;
    text-align: center;
    padding: 16px 12px 8px 12px;
    margin-top: 48px;

    h2 {
      font-size: 28px;
      line-height: 38px;
      text-transform: uppercase;
      margin-bottom: 0;

      span {
        font-size: 16px;
      }
    }
  }
}
