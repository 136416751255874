.event {
  min-height: 212px;
  background-color: $gray;
  border-bottom: 2px solid $yellow;

  &--vertical {
    height: 100%;
    min-height: auto;

    .event__title {
      letter-spacing: -0.67px;
    }

    .event__badge {
      background-color: $yellow;
      color: $gray-dark;
    }
  }

  &__title {
    font-size: 21px;
    line-height: 34px;
    font-weight: $semibold;

    a {
      text-decoration: none;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 19px;
    font-weight: $regular;
  }

  &__img {
    position: relative;
    background-color: $black;

    img {
      width: 100%;
    }
  }

  &__badge {
    position: absolute;
    top: 24px;
    left: 0;
    padding: 0 8px;
    background-color: $gray;
    font-size: 14px;
    font-weight: $semibold;
    color: $white;
    line-height: 36px;
  }

  &__date {
    svg {
      width: 17px;
      height: 17px;
    }
  }

  &__keyword {
    font-size: 12px;
    line-height: 26px;
    border: 1.5px solid $white;
    border-radius: 6px;
    padding: 0 10px;
    height: 26px;
  }

  .btn {
    min-width: 100px;
  }

  &__live {
    display: inline-block;
    background-color: $purple;
    padding: 10px;
    max-width: 130px;
    font-size: 14px;
    line-height: 19px;
    font-weight: $semibold;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      min-width: 130px;
    }
  }

  &__free {
    min-width: 130px;
    display: inline-block;
    background-color: $purple;
    padding: 10px;
    max-width: 130px;
    font-size: 14px;
    line-height: 19px;
    font-weight: $semibold;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
  }

  &__date {
    text-align: right;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  &__intro {
    font-size: 14px;
  }

  // &__content {
  //   padding: 25px 0 30px;
  // }
}
