.sponsor {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: $gray;
  padding-top: 5px;
  margin-top: 10px;

  &__btn {
    position: relative;
    z-index: 2;
    display: inline-block;
    background-color: $white;
    border-top: solid 1px $gray-light;
    border-right: solid 1px $gray-light;
    border-left: solid 1px $gray-light;
    border-bottom: 0;
    padding: 10px 20px;

    &:focus {
      outline: none;
    }

    &--arrow {
      &:after {
        content: '';
        border: solid $gray;
        border-width: 0px 2px 2px 0;
        display: inline-block;
        padding: 5px;
        margin: 0 0 2px 32px;
        transform: rotate(45deg);
      }
    }
  }

  &__all {
    float: right;
    font-size: 16px;
    margin-top: 8px;

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    background-color: $white;
    border: solid 1px $gray-light;
    padding: 11px;
    padding-left: 0;
    margin-top: -1px;
    display: none;
    flex-wrap: wrap;
    overflow: hidden;

    &:before {
      position: absolute;
      content: '';
      width: 3px;
      left: 15px;
      top: 0;
      bottom: 0;
      background-color: $white;
      z-index: 5;
    }

    &--open {
      display: flex;
      max-height: 150px;
      transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;

      @include media-breakpoint-down(lg) {
        max-height: 212px;
      }

      @include media-breakpoint-down(sm) {
        max-height: 305px;
      }

      @include media-breakpoint-down(xs) {
        max-height: 460px;
      }
    }

    &--transition-closed {
      display: flex;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    }
  }

  &__item {
    position: relative;
    border-left: solid 2px $gray-lightest;
    padding-top: 10px;
    padding-left: 19px;
    padding-bottom: 10px;
    margin-top: 19px;
    margin-left: 15px;

    width: calc(10% - 15px);

    @include media-breakpoint-down(lg) {
      width: calc(14.28% - 15px);
    }

    @include media-breakpoint-down(md) {
      width: calc(20% - 15px);
    }

    @include media-breakpoint-down(sm) {
      width: calc(33.3333% - 15px);
    }

    @include media-breakpoint-down(xs) {
      width: calc(50% - 15px);
    }

    img {
      max-width: 100%;
    }
  }

  &__role {
    position: absolute;
    top: -20px;
    left: 3px;
    white-space: nowrap;
    font-size: 13px;
    color: $gray;

    @include media-breakpoint-down(sm) {
      left: 10px;
    }
  }

  &__link {
    color: $red-dark;
    text-decoration: none;
  }
}
