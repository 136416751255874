.toggle {
  border: 2px solid $white;
  border-radius: 50%;
  background-color: transparent;
  width: 27px;
  min-width: 27px;
  height: 27px;

  &:focus {
    outline: none;
    // box-shadow: 0 0 2px 2px $white;
  }

  &:after {
    content: '';
    border: solid $white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: translate(0px, -4px) rotate(45deg);
    transition: transform 0.6s ease;
  }

  &--open {
    &:after {
      transform: translate(0px, -2px) rotate(-135deg);
    }
  }
}
