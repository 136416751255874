.form {
  &__label {
    width: 100%;
  }

  &__field {
    color: $white;
    line-height: 50px;
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    border: 1.5px solid $white;
    border-radius: 6px;
    padding: 0 12px;
    margin-top: 8px;
    background-color: transparent;

    &--ticket {
      @include media-breakpoint-up(md) {
        min-width: 250px;
      }
    }

    &--error {
      border-color: $red-dark;
      outline-color: $red-dark;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid $white;
      -webkit-text-fill-color: $white;
      -webkit-box-shadow: 0 0 0px 1000px $gray inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &__error {
    font-size: 16px;
    line-height: 24px;
  }

  &__err-message {
    font-size: 16px;
    line-height: 24px;
    color: $red-dark;
  }

  &__label-checkbox {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 4px;
      background-color: $gray-lightest;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 7px;
        height: 12px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: #2196f3;

        &:after {
          display: block;
        }
      }
    }
  }
}
