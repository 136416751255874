.festival-day {
  display: block;
  color: $white;
  background-color: $gray-dates;
  text-align: center;
  text-decoration: none;

  &:hover,
  &--today {
    text-decoration: none;
    color: $gray;
    background-color: $yellow;

    .festival-day__weekday {
      border-bottom-color: $gray;
    }
  }

  &__weekday {
    text-transform: capitalize;
    font-size: 18px;
    line-height: 26px;
    padding: 8px 0;
    border-bottom: 2px solid $blue-light;
  }

  &__date {
    font-size: 50px;
    line-height: 60px;
  }

  &__month {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 34px;
    padding-bottom: 8px;
  }
}
