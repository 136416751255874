.navigation {
  background-color: $red-dark;

  @include media-breakpoint-down(md) {
    background-color: $gray;
    padding: 0;
  }

  &__link {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    font-weight: $semibold;
    padding: 10px 12px 14px;
    text-decoration: none;
    text-align: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      border-bottom: 1px solid $gray-dark;
      padding: 10px 12px 14px;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &--active {
      background-color: $gray-dark;
      border-top: 2px solid $yellow;
    }
  }

  &__user {
    display: inline-flex;
    align-items: center;
    border: 2px solid $white;
    border-radius: 6px;
    height: 40px;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 10px;
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 8px;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }
}
