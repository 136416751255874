.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: $gray-dark;
  color: $white;
  z-index: 10000;
  padding: 24px 28px;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &__text {
    font-size: 16px;
  }

  &__btn {
    display: inline-block;
    color: $white;
    font-size: 16px;
    line-height: 50px;
    font-weight: $bold;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    min-width: 139px;
    background-color: $red;
    border: 0;
    border-radius: 6px;
    padding: 0 16px;
    margin-left: 24px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}
