.launch-box {
  position: relative;
  height: 680px;
  background-color: $gray;
  background-image: url('../../images/bg-launch-registrati.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom: 1px solid $gray-light;

  @include media-breakpoint-down(sm) {
    height: 580px;
  }

  &--stream {
    background-image: url('../../images/bg-launch-stream.jpg');

    &.no-bg {
      background-image: none;
      background-color: transparent;
    }
  }

  &--stream-creator {
    background-image: url('../../images/bg-launch-stream-creator.jpg');

    &.no-bg {
      background-image: none;
      background-color: transparent;
    }
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.8;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    margin: 0;
  }

  &__text {
    font-size: 35px;
    line-height: 40px;
    margin: 30px 0;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__tag {
    font-size: 28px;
    line-height: 30px;
    color: $yellow;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__logo {
    max-width: 350px;

    @include media-breakpoint-down(sm) {
      max-width: 200px;
    }
  }

  &__subtitle {
    font-family: $catamaran;
    font-size: 42px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 28px;
    line-height: 69px;

    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__date {
    font-size: 18px;
    line-height: 1;

    svg {
      max-width: 18px;
      margin-right: 12px;
    }
  }

  &__btn {
    margin-bottom: 30px;
  }
}
