.facets {
  padding: 20px 12px 32px 24px;
  border-radius: 11px;
  background: linear-gradient(180deg, rgba(#1f1e1e, 0.5) 0%, rgba(#4d4d4d, 0.5) 100%);
  font-size: 14px;
  margin-bottom: 16px;

  &__facet {
    margin-bottom: 20px;
  }

  &__show-more {
    border: 0;
    padding: 0;
    border-radius: 5px;
    background-color: transparent;
    font-size: 16;
    font-weight: bold;
    color: $white;

    &:focus {
      outline: none;
    }
  }

  &__label {
    cursor: pointer;
    color: $white;

    &.disabled {
      cursor: auto;
      opacity: 0.5;
    }

    &.active {
      color: $yellow;
      font-weight: $bold;
    }
  }

  &__value {
    color: inherit;
  }

  &__count {
    // color: #7a7a7a;
    color: $gray-light;
  }

  &__facet-name {
    width: 100%;
    text-align: left;
    font-size: 21px;
    line-height: 34px;
    font-weight: bold;
    color: $white;
    margin-bottom: 8px;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;

    &:focus {
      outline: 0;
    }

    &:before,
    &:after {
      content: '';
      background-color: $white;
      position: absolute;
      display: inline-block;
      height: 2px;
      width: 12px;
      right: 6px;
      top: 16px;
    }

    &:after {
      transform: rotate(0);
    }

    &:before {
      transform: rotate(90deg);
    }

    &.open {
      &:before {
        display: none;
      }
    }
  }

  &__reset {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: 0;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: $white;
    border-bottom: 1px solid rgba($white, 0.2);

    &:focus {
      outline: none;
    }

    svg {
      width: 12px;
      transform: scaleX(-1);
    }
  }

  &__facet-filter {
    margin-bottom: 0;
    max-height: calc(80vh - 36px);
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;

    $thumbBG: $gray-light;
    $scrollbarBG: transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }
    scrollbar-width: thin;
    scrollbar-color: $thumbBG $scrollbarBG;

    &::-webkit-scrollbar-track {
      background: $scrollbarBG;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $thumbBG;
      border-radius: 4px;
      border: 3px solid $scrollbarBG;
    }
  }

  &__facet-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out;

    &.open {
      max-height: 80vh;
    }
  }
}
