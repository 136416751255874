.header {
  background-color: $gray-dark;

  &__topbar {
    background-color: $red-dark;
  }

  &__logo {
    z-index: 5;
    @include media-breakpoint-up(lg) {
      margin-bottom: 52px;
    }
    img {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &__logo-stream-creator {
    z-index: 5;
    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }
    img {
      max-width: 450px;
      @include media-breakpoint-down(md) {
        max-width: 315px;
        margin: 50px 15px 0 15px;
      }
    }
  }

  &__logo-stream {
    z-index: 5;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
    img {
      max-width: 350px;
      @include media-breakpoint-down(md) {
        max-width: 280px;
        margin: 50px 15px 0 15px;
      }
    }
  }

  &__content {
    // @include media-breakpoint-up(lg) {
    //   height: 240px;
    // }
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }

  &__simbolo {
    position: relative;
    width: 160px;
    bottom: -20px;
    z-index: 3;

    @include media-breakpoint-down(md) {
      left: -205px;
    }
  }
}
