/* STYLE CHECKBOX
==================================================*/
$width: 13px;

.checkbox {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding-left: $width + 5px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:hover {
      ~ .checkbox__mark {
        color: $white;
      }
    }

    &:checked {
      ~ .checkbox__mark {
        background-color: $yellow;
        border-color: $yellow;

        &:after {
          display: block;
        }
      }
    }
  }

  &__mark {
    position: absolute;
    top: 0.35em;
    left: 0;
    height: $width;
    width: $width;
    border: solid 1px $white;
    border-radius: 2px;

    &:after {
      display: none;
      content: '';
      position: absolute;
      top: 0px;
      left: $width * 0.3;
      width: $width * 0.385;
      height: $width * 0.7;
      border: solid $gray-medium;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .checkbox__input {
      ~ .checkbox__mark {
        color: $white;
      }

      &:checked {
        ~ .checkbox__mark {
          border-color: $yellow;
        }
      }
    }
  }
}
