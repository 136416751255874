.home {
  &__sponsor {
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    hr {
      border-top: 2px solid $yellow;
      box-sizing: border-box;
      height: 1px;
    }
  }

  &__search-wrapper {
    max-width: 32px;
    padding-bottom: 8px;
    border-bottom: 1px solid transparent;
    transition: max-width 0.4s ease, border-color 0.4s ease;
    overflow: hidden;

    &.open {
      max-width: 416px;
      border-color: $yellow;
    }

    button {
      padding: 0;
      background-color: transparent;
      border: 0;
      outline: 0;
    }

    svg,
    .icon-close {
      color: $white;
      width: 32px;
      height: 32px;
    }
  }

  &__search-input {
    border: 0;
    background-color: transparent;
    padding: 8px;
    color: $white;
    outline: none;
    width: 250px;

    @include media-breakpoint-up(md) {
      width: 350px;
    }
  }

  &__hr {
    height: 1px;
    width: 100%;
    background-color: $white;
    opacity: 0.2;
  }

  &__filter-toggle {
    padding: 0;
    background-color: transparent;
    border: 0;
    color: $white;
    font-size: 18px;
    line-height: 26px;

    &:focus {
      // outline-style: solid;
      // outline-width: 1px;
      // outline-offset: 4px;
      // outline-color: $gray-light;
      outline: none;
    }

    svg {
      width: 18px;
    }

    // &.open {

    // }
  }

  &--stream {
    background: linear-gradient(to bottom, $gray-dark 0%, $gray-medium 100%);
    padding: 40px 0 24px 0;
    margin: -40px 0 -24px 0;
  }
}
