.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.has-background {
    background-color: $gray-dark;
    // background-image:  url('../../images/bg-launch.png');
    background-repeat: no-repeat;

    // &.stream-creator {
    //   background-image:  url('../../images/bg-stream-creator.jpg');
    // }

    // &.stream {
    //   .app__content {
    //     background: $gray-dark;
    //     background: linear-gradient(to bottom, $gray-dark 0%, $gray-medium 100%);
    //     background-repeat: no-repeat;
    //   }
    // }

    .app__content {
      background-color: transparent;
      background-image: none;
      background: none;
    }

    .section {
      &--slider {
        background-color: transparent;
      }
    }
  }

  &.sponsor-open {
    padding-bottom: 137px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 211px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 303px;
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 459px;
    }
  }

  &__content {
    flex: 1;
    flex-basis: auto;
    padding-top: 40px;
    background: $gray-dark;
    background: linear-gradient(to bottom, $gray-dark 0%, $gray-medium 100%);
    background-repeat: no-repeat;
  }
}
