.slick-slider {
  &.slider-events {
    margin-bottom: -58px;

    .slick-list {
      margin: 0 -15px;
    }
    .slick-slide > div {
      margin: 0 15px;
      height: 100%;
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -38px;
      margin: 0;
      padding-left: 0;
      padding-inline-start: 0;

      @include media-breakpoint-down(lg) {
        bottom: -50px;
      }

      li {
        display: inline-block;
        list-style-type: none;

        &.slick-active {
          button {
            opacity: 0.8;
          }
        }

        button {
          display: block;
          position: relative;
          width: 4px;
          height: 4px;
          text-indent: -9999em;
          background-color: $black;
          opacity: 0.4;
          border: 3px solid $white;
          border-radius: 50%;
          cursor: pointer;

          &:before {
            content: '';
          }
        }
      }
    }

    &--mb-large {
      @include media-breakpoint-down(md) {
        margin-bottom: -40px;

        .slick-dots {
          bottom: -70px;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      background: transparent;
      border: 0;
      opacity: 1;

      &.slick-prev,
      &.slick-next {
        top: 80px;
        left: -100px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 3px solid $white;
        transform: none;

        &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: 16px;
          width: 19px;
          height: 19px;
          border: solid $white;
          border-width: 3px 3px 0 0;
          transform: rotate(-135deg);
          opacity: 1;
        }
      }

      &.slick-next {
        left: auto;
        right: -100px;
        transform: rotate(180deg);
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
  }

  &.slider-stream-creator {
    margin-top: -70px;
    margin-bottom: 68px;

    .slick-track {
      @include media-breakpoint-down(md) {
        display: flex !important;
      }
    }

    .slick-slide {
      @include media-breakpoint-down(md) {
        height: inherit !important;
      }

      > div {
        @include media-breakpoint-down(md) {
          height: 100%;
        }

        > div {
          @include media-breakpoint-down(md) {
            height: 100%;
          }
        }
      }
    }

    .slick-list {
      margin: 0 -15px;
    }
    .slick-slide > div {
      margin: 0 15px;
      height: 100%;
    }

    .slick-arrow {
      position: absolute;
      background: transparent;
      border: 0;
      opacity: 1;

      &.slick-prev,
      &.slick-next {
        left: -100px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 3px solid $white;

        &:before {
          content: '';
          position: absolute;
          top: 14px;
          left: 16px;
          width: 18px;
          height: 18px;
          border: solid $white;
          border-width: 3px 3px 0 0;
          transform: rotate(-135deg);
          opacity: 1;
        }
      }

      &.slick-next {
        left: auto;
        right: -100px;
        transform: rotate(180deg);

        &:before {
          left: 16px;
        }
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0.6;
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -38px;
      margin: 0;
      padding-left: 0;
      padding-inline-start: 0;

      li {
        display: inline-block;
        list-style-type: none;
        width: auto;
        height: auto;

        &.slick-active {
          button {
            opacity: 0.8;
          }
        }

        button {
          display: block;
          position: relative;
          width: 4px;
          height: 4px;
          text-indent: -9999em;
          background-color: $gray-light;
          opacity: 0.3;
          border: 0;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  &.slider-launch-box {
    .slick-arrow {
      position: absolute;
      background: transparent;
      border: 0;
      opacity: 1;
      z-index: 5;

      &.slick-prev,
      &.slick-next {
        left: 100px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 3px solid $white;

        &:before {
          content: '';
          position: absolute;
          top: 14px;
          left: 16px;
          width: 18px;
          height: 18px;
          border: solid $white;
          border-width: 3px 3px 0 0;
          transform: rotate(-135deg);
          opacity: 1;
        }
      }

      &.slick-next {
        left: auto;
        right: 100px;
        transform: translate(0, -50%) rotate(180deg);

        &:before {
          left: 16px;
        }
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0.6;
      }
    }

    .slick-dots {
      bottom: 65px;

      @include media-breakpoint-down(sm) {
        bottom: 30px;
      }

      li {
        width: auto;
        height: auto;
        margin: 0 0 0 28px;

        &:first-child {
          margin: 0;
        }

        &.slick-active {
          button {
            &:before {
              background-color: $white;
            }
          }
        }

        button {
          width: 20px;
          height: 20px;
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 3px solid $white;
            opacity: 1;
          }
        }
      }
    }
  }

  &.slider-giornate {
    .slick-list {
      margin: 0 -15px;
    }
    .slick-slide > div {
      margin: 0 15px;
      height: 100%;
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -50px;
      margin: 0;
      padding-left: 0;
      padding-inline-start: 0;

      li {
        display: inline-block;
        list-style-type: none;

        &.slick-active {
          button {
            opacity: 0.8;
          }
        }

        button {
          display: block;
          position: relative;
          width: 4px;
          height: 4px;
          text-indent: -9999em;
          background-color: $black;
          opacity: 0.3;
          border: 3px solid $white;
          border-radius: 50%;
          cursor: pointer;

          &:before {
            content: '';
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      background: transparent;
      border: 0;
      opacity: 1;

      &.slick-prev,
      &.slick-next {
        top: 80px;
        left: -100px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 3px solid $white;
        transform: none;

        &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: 16px;
          width: 19px;
          height: 19px;
          border: solid $white;
          border-width: 3px 3px 0 0;
          transform: rotate(-135deg);
          opacity: 1;
        }
      }

      &.slick-next {
        left: auto;
        right: -100px;
        transform: rotate(180deg);
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
  }
}
