.card-giornate {
  width: 255px;
  height: 255px;
  background-color: black;
  color: $white;
  font-size: $regular;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &__bg {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(38, 38, 38, 0) 0.05%, rgba(0, 0, 0, 0.77) 100%);
  }

  &__content {
    position: relative;
    display: block;
    border-left: 2px solid $yellow;
    padding: 0 12px 0 12px;
    margin-bottom: 26px;
  }

  &__day {
    display: block;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
  }

  &__date {
    display: block;
    font-size: 35px;
    line-height: 40px;
  }
}
