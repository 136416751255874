.sponsor-new {
  border-top: 2px solid $yellow;
  padding-top: 60px;
  margin: 100px 0;

  &__bg {
    height: 82px;
    background-color: rgba($white, 0.7);
    margin-bottom: 18px;
    padding: 0 8px;
  }

  &__img {
    max-width: 100%;
    max-height: 78px;
  }

  &__text {
    color: $white;
    font-family: $catamaran;
    font-size: 21px;
    letter-spacing: -0.67px;
    line-height: 34px;
    margin-bottom: 30px;
  }
}
