.select {
  position: relative;

  select {
    position: relative;
    z-index: 4;
    width: 100%;
    border: 2px solid $white;
    border-radius: 6px;
    background-color: transparent;
    padding: 8px 35px 8px 20px;
    font-size: 18px;
    color: $white;
    text-overflow: ellipsis;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }

    option,
    optgroup {
      color: $black;
    }
  }

  &__arrow {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 13px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
  }
}
