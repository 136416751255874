.breadcrumbs {
  font-size: 14px;
  line-height: 24px;
  color: $white;
  margin-bottom: 20px;

  &__list {
    color: $white;
    margin: 0;
    padding: 16px 0;
  }

  &__item {
    display: inline-block;
    position: relative;
    list-style-type: none;
    color: $white;
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      padding-left: 15px;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 10px;
      top: 8px;
      border: solid $white;
      border-width: 0 1px 1px 0;
      padding: 2px;
      transform: rotate(-45deg);

      @include media-breakpoint-down(md) {
        left: 0;
      }
    }

    &:first-child {
      padding-left: 0;

      &:before {
        content: none;
      }
    }
  }

  &__link {
    color: rgba($white, 0.8);
    margin-right: 5px;
    text-decoration: none;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
