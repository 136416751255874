$size: 28px;
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 1.76 * $size;
  height: $size;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $red;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $red;
    }

    &:checked + .slider:before {
      transform: translateX($size * 0.76);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-light;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: $size * 0.76;
      width: $size * 0.76;
      left: $size * 0.117;
      bottom: $size * 0.117;
      background-color: $white;
      transition: 0.4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: $size;

      &:before {
        border-radius: $size/2;
      }
    }
  }
}

.labelled-switch {
  display: inline-flex;
  align-items: center;

  label {
    display: inline-block;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}
