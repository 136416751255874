.pushmenu {
  &__body {
    @include media-breakpoint-down(md) {
      position: relative;
      left: 0;
      transition: left 0.5s ease;

      &--open {
        overflow: hidden;
        left: 234px;
      }
    }
  }
  &__sidebar {
    @include media-breakpoint-down(md) {
      position: absolute;
      top: 0;
      left: -234px;
      z-index: 9999;
      width: 234px;
      height: 100%;
    }
  }
}
