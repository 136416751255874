.footer {
  padding: 12px 16px 16px 16px;
  background-color: $gray;

  a {
    font-size: 16px;
    line-height: 27px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
