.hamburger {
  position: relative;
  z-index: 999;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin-top: 11px;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background: $white;
    border-radius: 7px;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 12px;
    }

    &:nth-child(4) {
      top: 24px;
    }
  }

  &--open {
    span {
      &:nth-child(1) {
        top: 12px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        width: 0%;
        top: 12px;
        left: 50%;
      }
    }
  }
}
