.faq {
  hr {
    border-top: 2px solid $yellow;
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }

  &--expanded {
    .toggle {
      &:after {
        transform: translate(0px, -2px) rotate(-135deg);
      }
    }

    .faq__body {
      max-height: 500px;
    }
  }
}
