// Font Family
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600;700&display=swap');

$catamaran: 'Catamaran', sans-serif;

// $light:     300;
$regular: 400;
$semibold: 600;
$bold: 700;

// Colors
$white: #ffffff;
$black: #000000;

$red: #d70000;
$red-dark: #d2001f;
$yellow: #f5e302;

$blue: #0097d7;
$blue-light: #77e4ff;
$purple: #ce006f;

$gray-lightest: #eaeaea;
$gray-light: #979797;
$gray-medium: #454545;
$gray: #343434;
$gray-dates: #282828;
$gray-dark: #212121;
